// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .messageBoxArrow::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 10px 5px 0;
    border-color: transparent #000 transparent transparent !important;
    box-shadow: 0px 0px 5px 0px #0000001a;
} */
`, "",{"version":3,"sources":["webpack://./src/shared/utils/tooltip/tooltip-style.css"],"names":[],"mappings":"AAAA;;;;;;;;GAQG","sourcesContent":["/* .messageBoxArrow::before {\n    content: \"\";\n    width: 0;\n    height: 0;\n    border-style: solid;\n    border-width: 5px 10px 5px 0;\n    border-color: transparent #000 transparent transparent !important;\n    box-shadow: 0px 0px 5px 0px #0000001a;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
